import axios from 'axios'
import { route } from 'preact-router'
import { authStorage } from '../../storage'

export const getUser = () => {
    axios
        .post(`/user`)
        .then((response) => {
            authStorage.set('user', response?.data)
            return response.data
        })
        .catch((e) => {
            return e.response
        })
}

export const login = ({ email, password }) =>
    axios
        .post(`/login`, { email, password })
        .then((response) => {
            authStorage.set('token', response?.data?.accessToken)
            getUser()
            route('devicesList', true)
            return response.data
        })
        .catch((e) => {
            return e.response
        })

export const logout = () =>
    axios
        .post(`/logout`)
        .then(() => {
            authStorage.delete('token')
            route('/')
        })
        .catch((e) => {
            return e.response
        })
