export const DEVICE_STATUSES = {
    in_use: 'Kasutuses',
    in_stock: 'Laos',
    needs_repair: 'Vajab remonti',
}

export const DEVICE_TYPES = {
    nihik: 'Nihik',
    kaal: 'Kaal',
}

export const USER_STATUS_ADMIN = 'admin'
