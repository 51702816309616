import { isJSON } from '../utils'
import { observable, toJS } from 'mobx'

export const storage = (persist) => {
    const inMemoryStore = observable.map()
    return {
        set: (key, value) => {
            const finalValue = typeof value === 'object' ? JSON.stringify(value) : value
            if (persist) {
                window.localStorage.setItem(key, finalValue)
            }
            inMemoryStore.set(key, value)
        },
        get: (key) => {
            if (inMemoryStore.has(key)) {
                return toJS(inMemoryStore.get(key))
            } else {
                const value = window.localStorage.getItem(key)
                return isJSON(value) ? JSON.parse(value) : value
            }
        },
        has: (key) => {
            return inMemoryStore.has(key) || window.localStorage.getItem(key) !== undefined
        },
        delete: (key) => {
            window.localStorage.removeItem(key)
            inMemoryStore.delete(key)
        },
    }
}

export const authStorage = storage(true)
export const clientDeviceStorage = storage()
export const devicesStorage = storage()
export const clientsStorage = storage()
