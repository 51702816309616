import axios from 'axios'
import { authStorage } from './storage'

// Add a request interceptor
axios.interceptors.request.use(
    (config) => {
        const token = authStorage.get('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error)
    }
)

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        // if status is 401, then session has expired. Redirect user and delete token from localstorage
        if (error?.response.status === 401) {
            authStorage.delete('token')
            authStorage.delete('user')
            route('/', true)
        }
        return Promise.reject(error)
    }
)

export default axios
