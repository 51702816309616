import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { route, Router } from 'preact-router'

import axios from 'axios'
import { observer } from 'mobx-preact'

import { authStorage } from './storage'
import { USER_STATUS_ADMIN } from './utils/commonConstants'
import { getUser } from './routes/login/api'
import DevicesListAdmin from "./routes/devicesListAdmin"
//don't delete this. This is for axios interceptor
import apiClient from './api-client.js'

import Header from './components/header'

// Code-splitting is automated for `routes` directory
import Home from './routes/home'
import Profile from './routes/profile'
import Client from './routes/client'
import Device from './routes/device'
import Login from './routes/login'
import DevicesList from './routes/devicesList'
import Users from './routes/users'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datetime/css/react-datetime.css'
import 'react-datepicker/dist/react-datepicker.css'

axios.defaults.baseURL = process.env.PREACT_APP_API_URL

const Redirect = ({ to }) => {
    useEffect(() => {
        route(to, true)
    }, [])

    return null
}

const App = observer(() => {
    const userLoggedIn = authStorage.get('token')
    const user = authStorage.get('user')

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div id="app">
            <Header isLoggedIn={userLoggedIn} />
            <Home>
                {userLoggedIn ? (
                    <PrivateRoutes isAdmin={user?.userType === USER_STATUS_ADMIN} />
                ) : (
                    <Redirect to="/login" path="/xx" />
                )}
                <Router>
                    <Login path="/login" />
                </Router>
            </Home>
        </div>
    )
})

const PrivateRoutes = ({ isAdmin }) => (
    <Router>
        <Redirect path="/login" to="/devicesList" />
        <Redirect path="/" to="/devicesList" />
        <Client path="/client" />
        <Device path="/device" />
        {isAdmin ? <DevicesListAdmin path="/devicesList" /> : <DevicesList path="/devicesList" />}
        <Users path="/users" />
        <Profile path="/profile/" user="me" />
        <Profile path="/profile/:user" />
    </Router>
)

export default observer(App)
