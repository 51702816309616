import { h } from 'preact'
import { Link } from 'preact-router/match'
import { logout } from '../../routes/login/api'
import style from './style.css'

const Header = ({ isLoggedIn }) => {
    return (
        <header class={style.header}>
            <h1>Andmete sisestus</h1>
            {isLoggedIn && (
                <nav>
                    <Link activeClassName={style.active} href="/client">
                        Uus klient
                    </Link>
                    <Link activeClassName={style.active} href="/device">
                        Uus seade
                    </Link>
                    <Link activeClassName={style.active} href="/devicesList">
                        Seadmete nimekiri
                    </Link>
                    <Link activeClassName={style.active} href="/control">
                        Uus kontroll
                    </Link>
                    <Link activeClassName={style.active} href="/users">
                        Uus kasutaja
                    </Link>
                    <span className={style.active} onClick={() => logout()}>
                        Logi välja
                    </span>
                </nav>
            )}
        </header>
    )
}

export default Header
